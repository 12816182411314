import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { AppContextType } from '../../@types/Context';
import Button from '../Button/Button';
import { ItemFormMode } from '../../@types/ItemFormMode';
import TodoEdit from '../todo/TodoEdit';
import './ToolsContainer.scss';

function ToolsContainer() {
    const { itemForEdit, itemFormMode, addItemFormMode, addItemForEdit } = useContext(AppContext) as AppContextType;

    const onAddNew = () => {
        addItemFormMode(ItemFormMode.ADD);
    };

    const onTodoSave = () => {
        console.log('On save');
        addItemForEdit(null);
        addItemFormMode(null);
    };

    const onTodoCancel = () => {
        addItemForEdit(null);
        addItemFormMode(null);
    };

    const content = itemFormMode?.valueOf ?
        <TodoEdit
            onSave={onTodoSave}
            onCancel={onTodoCancel}
            todo={itemForEdit}
            mode={itemFormMode}
        /> :
        <Button
            theme='dark'
            type='submit'
            label='Dodaj zadanie'
            onClick={onAddNew}
        />;

    return (
        <div>
            {content}
        </div>
    );
}

export default ToolsContainer;