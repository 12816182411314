import { FC, FormEvent } from 'react';
import './Button.scss';

const Button: FC<Props> = (props) => {
    return (
        <button
            type={props.type}
            className={`button button-${props.theme}`}
            onClick={props.onClick}
        >
            {props.label}
        </button>
    );
};

interface Props {
    type: 'button' | 'submit' | 'reset',
    theme: 'dark' | 'bright',
    label: string,
    onClick: (e: FormEvent) => void,
};

export default Button;

