import { FC, forwardRef, useImperativeHandle, useEffect } from 'react';
import { SyntheticEvent, useState } from 'react';
import './InputDefault.scss';

export enum InputSize {
    HALF,
};

const InputDefault: FC<Props> = forwardRef((props, ref) => {
    const { size, name, label, type, required, min, initialValue, reset, onChange } = props;

    const [value, setValue] = useState<string>("");
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        } else {
            setValue("");
        }
    }, [initialValue]);

    useEffect(() => {
        if (reset) {
            resetField();
        }
    }, [reset]);

    useImperativeHandle(ref, () => ({
        check(): boolean {
            return validate();
        },
        reset(): void {
            resetField();
        },
    }));

    const handleFocus = () => {
        setIsFocus(true);
    };

    const handleBlur = () => {
        validate();
        setIsFocus(false);
    };

    const handleChange = (e: SyntheticEvent) => {
        e.preventDefault();
        if (isError) {
            setIsError(false);
            setErrorMessage(null);
        }
        const newValue = (e.target as HTMLInputElement).value;
        setValue(newValue);
        if (onChange !== undefined) {
            onChange(name, newValue);
        }
    }

    const validate = (): boolean => {
        if (required && !value) {
            setIsError(true);
            setErrorMessage("Pole obowiązkowe");
            return false;
        }

        if (type === 'email' && !!value && !validateEmail()) {
            setIsError(true);
            setErrorMessage("Wrong email format");
            return false;
        }

        if (type === 'tel' && !!value && !validateTel()) {
            setIsError(true);
            setErrorMessage("Wrong tel format");
            return false;
        }

        if (!!value && !validateMin()) {
            setIsError(true);
            setErrorMessage("Fraza za krótka");
            return false;
        }
        return true;
    };

    const validateEmail = () => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(value);
    };

    const validateTel = () => {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im.test(value);
    };

    const validateMin = () => {
        if (min !== undefined && min !== null) {
            return value.length >= min;
        }
    };

    const resetField = () => {
        setValue("");
    };

    const labelMoved = isFocus || !!value;

    const control = (type === 'textarea') ? (
        <textarea
            id={name}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
        />
    ) : (
        <input
            id={name}
            type={type}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
        />
    );

    return (
        <div
            className={`
                input-default 
                ${size === InputSize.HALF ? 'input-default--half' : ''}
                ${labelMoved ? 'is-filled' : ''}
                ${isError ? 'is-error' : ''}
        `}>
            {errorMessage &&
                <div className="input-default-message">
                    {errorMessage}
                </div>
            }
            <label htmlFor={name}>
                {label} {required && '*'}
            </label>
            {control}
        </div>
    );
});

interface Props {
    name: string,
    label: string,
    size?: InputSize,
    type?: 'text' | 'email' | 'number' | 'tel' | 'textarea' | 'date' | 'password',
    required?: boolean,
    min?: number,
    ref?: any,
    initialValue?: string,
    reset?: boolean,
    onChange?: (property: string, value: string) => void,
};

export default InputDefault;