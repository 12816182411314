import { getCookie, setCookie } from '../utils';
import { UserData } from '../@types/USerData';
import { Product } from '../@types/Product';
import { Todo } from '../@types/Todo';
import { Auth } from '../@types/Auth';

const BASE_URL = 'https://api.compiledsuccessfully.com/api';
const headers: any = {
    'Content-Type': 'application/json'
}
function checkCredentials() {
    let token = getCookie("token");
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
        return token;
    }
    return null;
}

export async function login(data: any): Promise<LoginResponse | null> {
    let out: LoginResponse | null = null;
    await fetch(`${BASE_URL}/login`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    }).then(async (response) => {
        if (response.ok) {
            let resp = await response.json();
            return resp;
        } else {
            try {
                const JSON = await response.json();
                return { msg: JSON.message, token: null, user: null };
            } catch (e) {
                return { msg: 'Coś poszło nie tak', token: null, user: null };
            }
        }
    }).then((responseJson) => {
        out = responseJson;
    }).catch((error) => {
        out = { msg: 'Nieznany błąd', token: null, user: null };
    })
    return out;
}

export async function getAction<T extends DataResponse>(path: string): Promise<T | null> {
    const token = checkCredentials();
    let out: T | null = null;
    await fetch(`${BASE_URL}${path}`, {
        headers: headers
    }).then(async (response) => {
        if (response.ok) {
            let resp = await response.json();
            resp['token'] = token;
            return resp;
        } else {
            try {
                const JSON = await response.json();
                return { error: true, data: null, message: JSON.message, token: null };
            } catch (e) {
                return { error: true, data: null, message: 'Coś poszło nie tak', token: null };
            }
        }
    }).then((responseJson) => {
        out = responseJson;
    }).catch((error) => {
        out = { error: true, data: null, message: 'Nieznany blad', token: null } as T;
    })
    return out;
}

export async function postAction(path: string, data: any): Promise<PostDataResponse | null> {
    const token = checkCredentials();
    let out: PostDataResponse | null = null;
    await fetch(`${BASE_URL}${path}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    }).then(async (response) => {
        if (response.ok) {
            let resp = await response.json();
            resp['token'] = token;
            resp['error'] = false;
            return resp;
        } else {
            try {
                const JSON = await response.json();
                return { error: true, msg: JSON.message, token: null };
            } catch (e) {
                return { error: true, msg: 'Coś poszło nie tak', token: null };
            }
        }
    }).then((responseJson) => {
        out = responseJson;
    }).catch((error) => {
        out = { error: true, msg: 'Nieznany błąd', token: null };
    })
    return out;
}

export async function deleteAction(path: string, data: any): Promise<PostDataResponse | null> {
    const token = checkCredentials();
    let out: PostDataResponse | null = null;
    await fetch(`${BASE_URL}${path}`, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(data),
    }).then(async (response) => {
        if (response.ok) {
            let resp = await response.json();
            resp['token'] = token;
            resp['error'] = false;
            return resp;
        } else {
            try {
                const JSON = await response.json();
                return { error: true, msg: JSON.message, token: null };
            } catch (e) {
                return { error: true, msg: 'Coś poszło nie tak', token: null };
            }
        }
    }).then((responseJson) => {
        out = responseJson;
    }).catch((error) => {
        out = { error: true, msg: 'Nieznany błąd', token: null };
    })
    return out;
}

interface DataResponse {
    error: boolean,
    data: any | null,
    message: string | null,
    token: string | null,
}

interface PostDataResponse {
    error: boolean,
    msg: string,
    token: string | null,
}

export interface UserDataResponse extends DataResponse {
    data: UserData | null,
}

export interface TodosDataResponse extends DataResponse {
    data: Todo[] | null,
}

export interface ProductsDataResponse extends DataResponse {
    data: Product[] | null,
}

interface LoginResponse {
    msg: string,
    token: string | null,
    user: any | null,
}