import { useContext, useEffect, useState } from 'react';
import { Todo } from '../../@types/Todo';
import { AppContext } from '../../context/AppContext';
import { AppContextType } from '../../@types/Context';
import TodoItem from './TodoItem';
import Spinner from '../spinner/Spinner';
import { getAction, TodosDataResponse } from '../../api/api';

function TodoList() {
    const { todoItems, addTodoItems, auth, addMessage } = useContext(AppContext) as AppContextType;
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        reloadTodoItems();
    }, []);

    const reloadTodoItems = () => {
        setLoading(true);
        getAction<TodosDataResponse>('/todos')
            .then((res) => {
                if (res?.error) {
                    setLoading(false);
                    addMessage({ type: 'error', text: 'Błąd pobierania zadań' });
                } else if (res?.data) {
                    addTodoItems(res.data);
                    setLoading(false);
                }
            })

    };

    const items = todoItems?.map(todo => {
        return (
            <TodoItem
                key={todo.id}
                item={todo}
            />
        );
    });

    const toDisplay = loading ? <Spinner /> : items;

    return (
        <div className="todo-list">
            {toDisplay}
        </div>
    );
}

export default TodoList;