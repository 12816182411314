import { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AppContextType } from '../../@types/Context';
import Spinner from '../spinner/Spinner';
import './Header.scss';

function Header() {
    const { todoItems } = useContext(AppContext) as AppContextType;

    const closestTodo = todoItems?.length ? todoItems[0] : null;

    const loaded = !!todoItems;

    const substractDate = (strDate: string): string => {
        let out = '';
        const closestDate = new Date(strDate);
        const today = new Date();
        const sub = closestDate.getTime() - today.getTime();
        if (sub < 0) {
            out = '0';
        } else {
            out = Math.ceil(sub / 86400000).toString();
        }
        return out;
    };

    const content = closestTodo ?
        (
            <div>
                <div>Dni pozostałe do najbliższego deadline: <span>{substractDate(closestTodo.deadline)}</span></div>
                <div>Zadanie: <span>{closestTodo.title}</span></div>
            </div>
        ) : (
            <div>Brak danych do wyświetlenia</div>
        );

    const toDisplay = !!todoItems ? content : <Spinner />;


    return (
        <header className="header">
            {toDisplay}
        </header>
    );
}

export default Header;