import { FC, useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { AppContextType } from '../../@types/Context';
import { Todo } from '../../@types/Todo';
import { GrEdit } from 'react-icons/gr';
import { SlMagnifierAdd } from 'react-icons/sl';
import './TodoItem.scss';
import { ItemFormMode } from '../../@types/ItemFormMode';

const TodoItem: FC<Props> = (props) => {
    const { id, title, description, created, deadline } = props.item;
    const { addDialogDetails, addItemForEdit, addItemFormMode } = useContext(AppContext) as AppContextType;

    const onDetails = () => {
        addDialogDetails(props.item);
    };

    const onEdit = () => {
        addItemForEdit(props.item);
        addItemFormMode(ItemFormMode.EDIT);
    };

    return (
        <div className='todo-item'>
            <div className='todo-item-title'>{title}</div>
            <div className='todo-item-deadline'>{deadline}</div>
            <div className='todo-item-action'>
                <button onClick={onDetails}>
                    <SlMagnifierAdd />
                </button>
                <button onClick={onEdit}>
                    <GrEdit />
                </button>
            </div>
        </div>
    );
}

interface Props {
    item: Todo,
};

export default TodoItem;