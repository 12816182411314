import { useContext, useEffect, useState } from 'react';
import { AppContextType } from '../../@types/Context';
import { AppContext } from '../../context/AppContext';
import Header from '../../components/header/Header';
import LoginForm from '../../components/login/LoginForm';
import Todos from '../main/Todos';
import DialogDetails from '../../components/dialog/DialogDetails';
import Message from '../../components/message/Mesage';
import Products from '../product/Products';
import Spinner from '../spinner/Spinner';
import { getAction, UserDataResponse } from '../../api/api'
import { setCookie } from '../../utils';
import './Layout.scss';

const Layout = () => {
    const { auth, addAuth } = useContext(AppContext) as AppContextType;
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        getAction<UserDataResponse>('/get-user')
            .then((res) => {
                if (res?.error) {
                    addAuth(null);
                } else if (res?.data) {
                    addAuth({ userId: res.data.id, userName: res.data.name, userRoleId: res.data.role_id, userToken: 'tokrn' });
                }
                setLoaded(true);
            })
    }, []);

    const onLogout = () => {
        setCookie('token', '', 0);
        addAuth(null);
    }

    return (
        <div className='layout'>
            <Message />
            {!loaded ?
                <Spinner /> :
                <div>
                    {auth ?
                        <div>
                            <div className='layout-menu'>
                                <ul className='layout-menu-list'>
                                    <li
                                        className={`layout-menu-list-item ${currentTabIndex === 0 ? 'is-active' : ''}`}
                                        onClick={() => setCurrentTabIndex(0)}
                                    >
                                        DO KUPIENIA
                                    </li>
                                    <li
                                        className={`layout-menu-list-item is-disabled ${currentTabIndex === 1 ? 'is-active' : ''}`}
                                        onClick={() => setCurrentTabIndex(1)}
                                    >
                                        DO ZROBIENIA
                                    </li>
                                    <li
                                        className='layout-menu-list-item'
                                        onClick={() => onLogout()}
                                    >
                                        Wyloguj
                                    </li>
                                </ul>
                            </div>
                            <div className='layout-container'>
                                <div className={`layout-tab ${currentTabIndex === 0 ? 'is-active' : ''}`}>
                                    <Products />
                                </div>
                                <div className={`layout-tab ${currentTabIndex === 1 ? 'is-active' : ''}`}>
                                    <Header />
                                    <Todos />
                                    <DialogDetails />

                                </div>
                            </div>
                        </div> :
                        <LoginForm />
                    }
                </div>
            }
        </div>
    );
};

export default Layout;