import TodoList from '../todo/TodoList';
import './Todos.scss';
import ToolsContainer from '../tools/ToolsContainer';

function Todos() {
    return (
        <div className='todos'>
            <div className='todos-content'>
                <h1 className='todos-title'>Zadania do zrobienia</h1>
                <TodoList />
            </div>
            <div className='todos-tools'>
                <ToolsContainer />
            </div>
        </div>
    );
}

export default Todos;