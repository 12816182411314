import { FC, useContext, useEffect, useState } from 'react';
import './DialogDetails.scss';
import { AppContext } from '../../context/AppContext';
import { AppContextType } from '../../@types/Context';
import { GrClose } from 'react-icons/gr';

const DialogDetails: FC = () => {
    const { dialogDetails, addDialogDetails } = useContext(AppContext) as AppContextType;
    const [isVisible, setIsVisible] = useState<boolean>(false);


    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            addDialogDetails(null);
        }, 300)
    };

    useEffect(() => {
        if (dialogDetails) {
            if (isVisible) {
                setIsVisible(false);
                setTimeout(() => {
                    setIsVisible(true);
                }, 300);
            } else {
                setTimeout(() => {
                    setIsVisible(true);
                }, 100);
            };
        }
    }, [dialogDetails]);

    return (
        <>
            {dialogDetails &&
                <div className={`
                    dialog-details
                    ${isVisible ? 'is-visible' : ''}
                `}>
                    <div className='dialog-details-content'>
                        <div className='dialog-details-close' onClick={handleClose}>
                            <GrClose />
                        </div>
                        <div className='dialog-details-content-title'>
                            {dialogDetails.title}
                        </div>
                        <div className='dialog-details-content-description'>
                            {dialogDetails.description}
                        </div>
                        <div className='dialog-details-content-deadline'>
                            Deadline: {dialogDetails.deadline}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default DialogDetails;
