import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AppContextType } from '../../@types/Context';
import ProductItem from './ProductItem';
import Spinner from '../spinner/Spinner';
import { getAction, ProductsDataResponse } from '../../api/api';

const ProductsList: FC<Props> = (props) => {
    const { products, addProducts, addMessage } = useContext(AppContext) as AppContextType;
    const [loading, setLoading] = useState<boolean>(true);
    const { reloadOnNew, onFinishReloading, onRemoveProduct } = props;

    useEffect(() => {
        reloadProductsItems();
    }, []);

    useEffect(() => {
        if (reloadOnNew) {
            reloadProductsItems();
        }
    }, [reloadOnNew]);

    const reloadProductsItems = () => {
        setLoading(true);
        getAction<ProductsDataResponse>('/products')
            .then((res) => {
                if (res?.error) {
                    setLoading(false);
                    addMessage({ type: 'error', text: 'Błąd pobierania produktów' });
                } else if (res?.data) {
                    addProducts(res.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                onFinishReloading();
            })

    };

    const items = products?.map(product => {
        return (
            <ProductItem
                key={product.id}
                id={product.id}
                name={product.name}
                handleRemove={() => onRemoveProduct(product.id)}
            />
        );
    });

    const toDisplay = loading ? <Spinner /> : items;

    return (
        <div className="todo-list">
            {toDisplay}
        </div>
    );
}

interface Props {
    reloadOnNew: boolean,
    onFinishReloading: () => void,
    onRemoveProduct: (id: string) => void,
};

export default ProductsList;