import InputDefault from "../Input/InputDefault";
import Button from "../Button/Button";
import Spinner from "../spinner/Spinner";
import { FormEvent, useState, useRef, useContext } from 'react';
import { AppContextType } from '../../@types/Context';
import { AppContext } from '../../context/AppContext';
import { login } from "../../api/api";
import { setCookie } from "../../utils";
import './LoginForm.scss';

function LoginForm() {
    const defaultPayload: Payload = {
        email: "",
        password: "",
    };

    const { addMessage, addAuth } = useContext(AppContext) as AppContextType;

    const [payload, setPayload] = useState<Payload>(defaultPayload);
    const [loading, setLoading] = useState<boolean>(false);

    const inputEl = useRef<RefElement[]>([]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        let formValid = true;
        if (inputEl?.current) {
            inputEl.current.forEach(el => {
                if (!el.check()) {
                    formValid = false;
                }
            });
        }
        if (formValid) {
            login(payload)
                .then((res) => {
                    if (res?.token && res?.user) {
                        addAuth({ userId: res.user.id, userName: res.user.name, userRoleId: res.user.role_id, userToken: res.token });
                        addMessage({ type: 'success', text: `Witaj ${res.user.name} !` });
                        setLoading(false);
                        setCookie('token', res.token, 30);
                        resetForm();
                        // ustawić contextr message
                    } else {
                        setLoading(false);
                        addMessage({ type: 'error', text: 'Błąd logowania' });
                    }
                })

        } else {
            addMessage({ type: 'error', text: 'Wypełnij poprawnie wszystkie wymagane pola' });
            setLoading(false);
        }
    };

    const resetForm = () => {
        setPayload(defaultPayload);
        if (inputEl?.current) {
            inputEl.current.forEach(el => {
                el.reset();
            });
        }
    };

    const handleInputChange = (property: string, value: string) => {
        const updatedProperty = { [property]: value }
        setPayload(payload => ({
            ...payload,
            ...updatedProperty
        }));
    };

    return (
        <div className='login-form'>
            <div className={`login-form-container ${loading ? 'is-faded' : ''}`}>
                <div className='login-form-fields'>
                    <InputDefault
                        name='email'
                        label='Email'
                        type='email'
                        required
                        min={5}
                        onChange={handleInputChange}
                        ref={(el: RefElement) => inputEl.current[0] = el}
                    />
                    <InputDefault
                        name='password'
                        label='Password'
                        type='password'
                        required
                        min={5}
                        onChange={handleInputChange}
                        ref={(el: RefElement) => inputEl.current[1] = el}
                    />
                    <div className='login-form-separator' />
                    <Button
                        type='submit'
                        theme='dark'
                        label='Zaloguj'
                        onClick={handleSubmit}
                    />
                </div>
            </div>
            {loading && <Spinner />}
        </div>
    );
}

interface Payload {
    email: string,
    password: string,
};

interface RefElement {
    check: () => boolean,
    reset: () => void,
};

export default LoginForm;