import { FC } from 'react';
import Button from '../Button/Button';
import InputDefault from '../Input/InputDefault';
import { Todo } from '../../@types/Todo';
import { ItemFormMode } from '../../@types/ItemFormMode';
import './TodoEdit.scss';

const TodoEdit: FC<Props> = (props) => {

    const head = props.mode === ItemFormMode.ADD ?
        'Nowe zadanie' :
        `Edycja zadania id: ${props.todo?.id}`

    return (
        <div className='todo-edit'>
            <div className='todo-edit-head'>{head}</div>
            <div className='todo-edit-fields'>
                <InputDefault
                    name='tilte'
                    label="Tytuł"
                    min={3}
                    required
                    initialValue={props.todo?.title}
                />
                <InputDefault
                    name='deadline'
                    label="Deadline"
                    min={3}
                    required
                    type='date'
                    initialValue={props.todo?.deadline}
                />
                <InputDefault
                    name='description'
                    label="Opis"
                    min={3}
                    required
                    type='textarea'
                    initialValue={props.todo?.description}
                />
            </div>
            <div className='todo-edit-actions'>
                <Button
                    theme='dark'
                    type='submit'
                    label='Zapisz'
                    onClick={props.onSave}
                />
                <Button
                    theme='bright'
                    type='reset'
                    label='Anluj'
                    onClick={props.onCancel}
                />
            </div>
        </div>
    );
}

interface Props {
    mode?: ItemFormMode,
    todo?: Todo | null,
    onSave: () => void,
    onCancel: () => void,
};

export default TodoEdit;