import { FC } from 'react';
import { GrClose } from 'react-icons/gr';
import './ProductItem.scss';

const ProductItem: FC<Props> = (props) => {
    const { id, name, handleRemove } = props;

    return (
        <div className='product-item'>
            <div className='product-item-title'>{name}</div>
            <div className='product-item-action'>
                <button onClick={handleRemove}>
                    <GrClose />
                </button>
            </div>
        </div>
    );
}

interface Props {
    id: string,
    name: string,
    handleRemove: () => void
};

export default ProductItem;