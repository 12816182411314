import { FC, useContext, useEffect, useState } from 'react';
import { AppContextType } from '../../@types/Context';
import { AppContext } from '../../context/AppContext';
import { GrClose } from 'react-icons/gr';
import './Message.scss';

let tO: NodeJS.Timeout | null = null;

const Message: FC = () => {
    const { message, addMessage } = useContext(AppContext) as AppContextType;
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            addMessage(null);
        }, 300)
    };

    useEffect(() => {
        if (tO) {
            clearTimeout(tO);
        }
        if (message) {
            if (isVisible) {
                setIsVisible(false);
                setTimeout(() => {
                    setIsVisible(true);
                }, 300);
            } else {
                setTimeout(() => {
                    setIsVisible(true);
                }, 100);
            }
            tO = setTimeout(() => {
                handleClose();
            }, 5000);
        }
    }, [message]);

    return (
        <>
            {message &&
                <div className={`
                    message 
                    message--${message.type}
                    ${isVisible ? 'is-visible' : ''}
                `}>
                    {message?.text}
                    <div className='message-close' onClick={handleClose}>
                        <GrClose />
                    </div>
                </div>
            }
        </>
    );
}

export default Message;