import { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AppContextType } from '../../@types/Context';
import ProductsList from './ProductsList';
import InputDefault from '../Input/InputDefault';
import Button from '../Button/Button';
import Spinner from '../spinner/Spinner';
import { postAction, deleteAction } from '../../api/api';
import './Products.scss';

function Products() {
    const { addProducts, addMessage } = useContext(AppContext) as AppContextType;
    const [newProduct, setNewProduct] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [reloadingProducts, setReloadingProducts] = useState<boolean>(false);

    const onAddProduct = () => {
        postAction('/products', {
            name: newProduct,
        })
            .then((res) => {
                if (res?.error) {
                    setLoading(false);
                    addMessage({ type: 'error', text: 'Błąd dodawania produktu' });
                } else {
                    addMessage({ type: 'success', text: 'Dodano produkt!' });
                    setLoading(false);
                    setReloadingProducts(true);
                }
            })
    }

    const onRemoveProduct = (id: string) => {
        deleteAction('/products', {
            id,
        })
            .then((res) => {
                if (res?.error) {
                    setLoading(false);
                    addMessage({ type: 'error', text: 'Błąd usuwania produktu' });
                } else {
                    addMessage({ type: 'success', text: `Usunięto produkt ${id}` });
                    setLoading(false);
                    setReloadingProducts(true);
                }
            })
    }

    const resetInput = () => {

    }

    return (
        <div className='products'>
            <div className='products-content'>
                <h1 className='products-title'>Lista zakupów</h1>
                <ProductsList
                    reloadOnNew={reloadingProducts}
                    onFinishReloading={() => setReloadingProducts(false)}
                    onRemoveProduct={(id) => onRemoveProduct(id)}
                />
            </div>
            <div className='products-tools'>
                <div className='products-tools-title'>
                    Dodaj nowy
                </div>
                <div className='products-tools-input'>
                    <InputDefault
                        label='Nazwa produktu'
                        name='product'
                        onChange={(p, v) => setNewProduct(v)}
                        min={1}
                        reset={reloadingProducts}
                    />
                    <Button
                        label='Dodaj'
                        onClick={onAddProduct}
                        type='submit'
                        theme='dark'
                    />
                </div>
            </div>
        </div>
    );
}

export default Products;