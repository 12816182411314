import './App.scss';
import AppProvider from './context/AppContext';
import Layout from './components/layout/Layout';

function App() {
  return (
    <div className="App">
      <AppProvider>
          <Layout />
      </AppProvider>
    </div>
  );
}

export default App;
