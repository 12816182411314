import { FC, createContext, useState } from "react";
import { AppContextType } from "../@types/Context";
import { Auth } from "../@types/Auth";
import { Message } from "../@types/Message";
import { Product } from "../@types/Product";
import { Todo } from "../@types/Todo";
import { ItemFormMode } from "../@types/ItemFormMode";


export const AppContext = createContext<AppContextType | null>(null);

const AppProvider: FC<Props> = ({ children }) => {
    const [auth, setAuth] = useState<Auth | null>(null);
    const [message, setMessage] = useState<Message | null>(null);
    const [dialogDetails, setDialogDetails] = useState<Todo | null>(null);
    const [itemForEdit, setItemForEdit] = useState<Todo | null>(null);
    const [itemFormMode, setItemFormMode] = useState<ItemFormMode | null>(null);
    const [todoItems, setTodoItems] = useState<Todo[] | null>(null);
    const [products, setProducts] = useState<Product[] | null>(null);

    const addAuth = (auth: Auth | null) => {
        setAuth(auth);
    };

    const addMessage = (message: Message | null) => {
        setMessage(message);
    };

    const addDialogDetails = (details: Todo | null) => {
        setDialogDetails(details);
    };

    const addItemFormMode = (mode: ItemFormMode | null) => {
        setItemFormMode(mode);
    };

    const addItemForEdit = (item: Todo | null) => {
        setItemForEdit(item);
    };

    const addTodoItems = (items: Todo[] | null) => {
        setTodoItems(items);
    };

    const addProducts = (items: Product[] | null) => {
        setProducts(items);
    };

    return (
        <AppContext.Provider value={{ auth, message, dialogDetails, itemFormMode, itemForEdit, todoItems, products, addAuth, addMessage, addDialogDetails, addItemFormMode, addItemForEdit, addTodoItems, addProducts }}>
            {children}
        </AppContext.Provider>
    );
};

interface Props {
    children: any,
}

export default AppProvider;
